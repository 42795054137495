<template>
  <div
    class="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
    <div class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      aria-hidden="true">
      <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
        style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)" />
    </div>
    <div class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      aria-hidden="true">
      <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
        style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)" />
    </div>
    <div class="flex flex-wrap items-center gap-x-4 gap-y-2">
      <p class="text-sm leading-6 text-gray-900">
        <strong class="font-semibold">In 2024, it's all about now-or-never feedback</strong><svg
          viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
          <circle cx="1" cy="1" r="1" />
        </svg>Businesses gotta ride the wave of instant insights to stay on top of their game and keep customers grinning.
      </p>

    </div>
    <!-- Read More Button -->
    <div class="flex flex-1 justify-end">
      <a href="https://research.aimultiple.com/customer-feedback/" target="_blank" rel="noopener noreferrer"
        class="text-white bg-gray-900 hover:bg-gray-700 py-1 px-4 rounded">
        Read More
      </a>
    </div>
  </div>
  <div class="bg-gray-900">
    <main>
      <!-- Hero section -->
      <div class="relative isolate overflow-hidden">
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">EchoPod</span>
            <img class="h-12 w-auto" src="@/assets/images/echoPod_logo_final-01.svg" alt="EchoPod Logo" />
          </a>
        </nav>
        <svg
          class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true">
          <defs>
            <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1"
              patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="-1" class="overflow-visible fill-gray-800/20">
            <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              stroke-width="0" />
          </svg>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
        </svg>
        <div
          class="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
          aria-hidden="true">
          <div class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
        </div>
        <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8 lg:pt-10">
          <div class="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
            <div class="sm:mt-32 lg:mt-16">
              <a href="#" class="inline-flex space-x-6">
                <span
                  class="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">Beta
                  Release</span>
                <span class="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                  <span>End 1st Quarter 2024</span>
                  <ChevronRightIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                </span>
              </a>
            </div>
            <h1 class="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">Magic Feedback Beans: From Thoughts to Thrills
            </h1>
            <p class="mt-6 text-lg leading-8 text-gray-300">Sprinkle some AI magic on feedback, and presto—happy customers and peppy teams. Witness the wonder as insights spark action!</p>
            <!-- First Email Capture Form -->
            <form class="mt-10 flex max-w-md gap-x-4" @submit.prevent="submitEmail('form1')">
              <label for="email-address" class="sr-only">Email address</label>
              <input id="email-address" name="email" type="email" autocomplete="email" required="" v-model="emailForm1"
                class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                placeholder="Enter your email" />
              <button type="submit" @click="showSuccessAlert = true"
                class="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Notify
                me</button>
            </form>
            <div class="py-10 sm:py-10">
              <div class="mx-auto">
                <h2 class="text-center sm:text-left text-sm font-semibold leading-8 text-white">Plug in our chatty bot. Anyplace, Anytime!</h2>
                <div
                  class="mx-auto mt-6 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                  <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="@/assets/images/js.svg" alt="JavaScript Logo" width="158"
                    height="48" />
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="@/assets/images/shopify.svg" alt="Shopify Logo" width="158"
                    height="48" />
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="@/assets/images/wordpress-logo.svg" alt="WordPress Logo" width="158"
                    height="48" />
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="@/assets/images/api.svg" alt="API Logo" width="158"
                    height="48" />
                  
                </div>
              </div>
            </div>
          </div>
          <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <img src="@/assets/images/echopod-hero-dashboard.jpg" alt="App screenshot" width="2432" height="1442"
                class="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10" />
            </div>
          </div>
        </div>
      </div>

      <!-- Logo cloud -->

      <!-- Feature section -->
      <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-42 lg:px-8">
        <div class="mx-auto max-w-2xl text-center">
          <h2 class="text-base font-semibold leading-7 text-indigo-400">Efficiency Amplified</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Where Feedback Gets Clever
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-300">Where every voice counts. We mix your users and teams insights and sprinkle a little AI to craft one clever roadmap. It's straightforward, jargon-free, and all about getting you the results you want.</p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            <div v-for="feature in primaryFeatures" :key="feature.name" class="flex flex-col">
              <dt class="text-base font-semibold leading-7 text-white">
                <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-500">
                  <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                {{ feature.name }}
              </dt>
              <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p class="flex-auto">{{ feature.description }}</p>
                <p class="mt-6">

                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <!-- Feature section -->
      <div class="mt-24 sm:mt-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-2xl sm:text-center">
            <h2 class="text-base font-semibold leading-7 text-indigo-400">Your Upgrade from</h2>
            <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Yesterday's Feedback Gear</p>
            <p class="mt-6 text-lg leading-8 text-gray-300">EchoPod isn't just a tool; it's a revolution in hearing what your users really say. It turns whispers into roars, transforming feedback into the kind of insights that shape smart moves and real results.</p>
          </div>
        </div>
        <div class="relative overflow-hidden pt-16">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <img src="@/assets/images/echopod-chatbot.jpg" alt="App screenshot"
              class="mb-[-2%] rounded-xl shadow-2xl ring-1 ring-white/10" width="2432" height="1442" />
            <div class="relative" aria-hidden="true">
              <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
            </div>
          </div>
        </div>
        <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
          <dl
            class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            <div v-for="feature in secondaryFeatures" :key="feature.name" class="relative pl-9">
              <dt class="inline font-semibold text-white">
                <component :is="feature.icon" class="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                {{ feature.name }}
              </dt>
              {{ ' ' }}
              <dd class="inline">{{ feature.description }}</dd>
            </div>
          </dl>
        </div>
      </div>

      <!-- Stats -->
      <div class="bg-gray-900 pt-24 sm:pt-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-2xl lg:max-w-none">
            <div class="text-center">
              <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">EchoPod: Tuning Into Tomorrow</h2>
              <p class="mt-4 text-lg leading-8 text-gray-300">EchoPod's not just another feedback tool. It's your AI-powered co-pilot for navigating the vast ocean of customer voices,<br>mapping out a smarter, sassier course for your business's future.</p>
            </div>
            <dl
              class="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
              <div v-for="stat in stats" :key="stat.id" class="flex flex-col bg-white/5 p-8">
                <dt class="text-sm font-semibold leading-6 text-gray-300">{{ stat.name }}</dt>
                <dd class="order-first text-3xl font-semibold tracking-tight text-white">{{ stat.value }}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <!-- CTA section -->
      <div class="relative isolate mt-20 px-6 py-52 sm:mt-20 sm:py-50 lg:px-8">
        <svg
          class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true">
          <defs>
            <pattern id="1d4240dd-898f-445f-932d-e2872fd12de3" width="200" height="200" x="50%" y="0"
              patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="0" class="overflow-visible fill-gray-800/20">
            <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              stroke-width="0" />
          </svg>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
        </svg>
        <div class="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true">
          <div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
        </div>
        <div class="mx-auto max-w-2xl text-center">
          <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Leap Ahead.<br>First Dibs on Fresh Features
          </h2>
          <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">Slide into our mailing list for a backstage pass to the latest buzz and be the front runner to snag our nifty new tools.</p>

          <!-- Second Email Capture Form -->
          <form class="mx-auto mt-10 flex max-w-md gap-x-4" @submit.prevent="submitEmail('form2')">
            <label for="email-address" class="sr-only">Email address</label>
            <input v-model="emailForm2" id="email-address" name="email" type="email" autocomplete="email" required=""
              class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
              placeholder="Enter your email" />
            <button type="submit" @click="showSuccessAlert = true"
              class="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Notify
              me</button>
          </form>

        </div>
      </div>
    </main>

    <!-- Footer -->
    <footer aria-labelledby="footer-heading" class="relative">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
        <div class="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
          <div class="flex space-x-6 md:order-2">

          </div>
          <p class="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">&copy; 2024 EchoPod, Inc. All rights
            reserved.</p>
        </div>
      </div>
    </footer>
  </div>

  <!-- Success Alert, shown only when showSuccessAlert is true -->
  <div v-if="showSuccessAlert"
    class="slide-up fixed left-1/2 bottom-100 transform -translate-x-1/2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded"
    role="alert">
    <div class="flex">
      <div class="flex-shrink-0">
        <!-- Check Circle Icon -->
        <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <p class="text-sm font-medium text-green-800">Successfully signed up for notifications!</p>
      </div>
      <div class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <!-- Dismiss Button -->
          <button @click="showSuccessAlert = false" type="button"
            class="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            <span class="sr-only">Dismiss</span>
            <!-- XMark Icon -->
            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import {
  ArrowPathIcon,
  ChevronRightIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
  XMarkIcon,
  //CheckCircleIcon
} from '@heroicons/vue/20/solid';
import {

  ArrowsRightLeftIcon,
  CubeTransparentIcon,
  ChartBarIcon,
  //MagnifyingGlassCircleIcon,
  //UserCircleIcon,
  //SparklesIcon,
  ChatBubbleLeftEllipsisIcon,
  Square3Stack3DIcon,
  ViewfinderCircleIcon,
  ArrowsPointingOutIcon
} from '@heroicons/vue/24/outline';
import { supabase } from './supabase';

export default {
  name: 'App',
  components: {
    ArrowPathIcon,
    ChevronRightIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
    XMarkIcon,
    // ... include other components as needed
    // CheckCircleIcon (uncomment if used in template)
  },
  setup() {
    const emailForm1 = ref('');
    const emailForm2 = ref('');
    const showSuccessAlert = ref(false);

    const primaryFeatures = ref([
      {
        name: 'Slick Feedback Harmony & Insight Brewing',
        description:
          "Blend your feedback tunes and brew insights without breaking a sweat. EchoPod’s your sidekick for boosting the chatter that matters, slotting it right into your game plan.",
        href: '#',
        icon: ArrowsRightLeftIcon,
      },
      {
        name: "Ahead-of-the-Curve Analytics",
        description:
          "Hop to the front of the line and shape what's ahead with analytics. It's more than gathering thoughts—it's about cracking the code to smart, ahead-of-the-game choices that put you in the lead.",
        href: '#',
        icon: ChartBarIcon,
      },
      {
        name: "Cut Through the Clutter, Catch the Next Wave",
        description:
          "EchoPod takes the guesswork out, turning data into your playbook for what's coming up. It's smart, it's witty—no buzzwords, just clear insights that work for you.",
        href: '#',
        icon: CubeTransparentIcon,
      },
    ]);

    const secondaryFeatures = ref([
      {
        name: "Chatbot Chameleon:",
        description: "Make your chatbot morph with the mood, thanks to EchoPod's slick setup. Shape conversations with a tap, using real talk from users to dial up the engagement.",
        icon: ChatBubbleLeftEllipsisIcon,
      },
      {
        name: "EchoPod's Banter Builder:",
        description: "Craft chat that clicks with EchoPod. It's where AI meets real chatter, turning feedback into conversations that captivate and connect.",
        icon: Square3Stack3DIcon,
      },
      {
        name: "Feel the Pulse:",
        description: "Catch vibes on the fly with EchoPod's sentiment savvy. Drag, drop, and discover what your customers are really feeling, making every interaction count.",
        icon: ViewfinderCircleIcon,
      },
      {
        name: "Team Talk Transformers:",
        description: "EchoPod turns group genius into chatbot smarts. It's where collective insights and creativity brew bots that get your users, really get them.",
        icon: ArrowsRightLeftIcon,
      },
      {
        name: "Insight Infusion:",
        description: "EchoPod's the maestro of mixing feedback into chat flows. It's all about automating smarts to keep your conversations fresh and your users nodding along.",
        icon: ChartBarIcon,
      },
      {
        name: "Chatbot Choreography:",
        description: "From first sketch to final show, Orchestrate every step with feedback flair. Making sure your bots are not just talking, but truly engaging.",
        icon: ArrowsPointingOutIcon,
      },
    ]);

    const stats = ref([
      { id: 1, name: "Will Pay More for Brands With Stellar Service", value: "68%" },
      { id: 2, name: "Increase Retention for a 25-95% Profit Boost", value: "5%" },
      { id: 3, name: "Stay Loyal After Top-Notch Customer Care", value: "86%" },
      { id: 4, name: "Customers leave after one bad experience with a brand", value: "32%" },
    ]);
    

    async function submitEmail(formId) {
      // Retrieve the appropriate email value based on the form ID.
      const email = formId === 'form1' ? emailForm1.value : emailForm2.value;

      try {
        // Attempt to insert the email into the 'leads' table.
        const { error } = await supabase.from('leads').insert([{ email, source: formId }]);

        // If there's an error, throw it to be caught by the catch block.
        if (error) throw error;

        // If no error occurred, proceed to clear the input and show the success alert.
        if (formId === 'form1') {
          emailForm1.value = '';
        } else {
          emailForm2.value = '';
        }
        showSuccessAlert.value = true;
      } catch (error) {
        // If an error occurs during the insert operation, log it to the console.
        console.error('Error submitting lead:', error);
      }
    }

    return {
      emailForm1,
      emailForm2,
      showSuccessAlert,
      primaryFeatures,
      secondaryFeatures,
      stats,
      submitEmail
    };
  }
}
</script>

<style>
.slide-up {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  /* Start out of sight */
  z-index: 1000;
  /* Ensure it's above other content */
  transition: all 0.5s ease-in-out;
  animation: slideUp 0.5s forwards;
  /* Use forwards to keep the final state */
  animation-delay: 0.5s;
  /* Delay the animation to allow the slideUp class to be added */

}

@keyframes slideUp {
  to {
    bottom: 100px;
    /* Final position 100px above the bottom of the screen */
  }
}</style>

